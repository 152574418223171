import React, {useEffect, useState, useMemo,} from "react";
import { withRouter } from "react-router-dom";
import checkHash from '../../helpers/checkHash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// @material-ui/core components
import {
  Grid,
  Typography,
  Button,
  Hidden,
  Drawer,
  Fab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuRounded from "@material-ui/icons/MenuRounded";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UserIcon from '../UserIcon';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import withWidth from '@material-ui/core/withWidth';

//Core components
import { Link } from "react-router-dom";
import classNames from "classnames";

//Other Components
import HomeHeaderLogo from "./HomeHeaderLogo";
import AnchorLinkComponent from "./AnchorLinkComponent";
//Style
import elipseShineIso from "assets/img/cm-isotype-dark.svg";
import styles from "assets/jss/material-dashboard-react/components/homeHeaderStyle";
import {cmsHelpers, getCmsPosts} from "../../helpers/cmsHelpers";
import { serviceMenu } from '../../variables/InitState';
const useStyles = makeStyles(styles);

const HomeHeader = ({pageYOffset = 150, ...rest}) => {
  const classes = useStyles();
  const [theDrawer, setTheDrawer] = useState(false);
  const [headerOn, setHeaderOn] = useState(false);
  const [cmsPages, setCmsPages] = useState([]);
  const [anchorElService, setAnchorElService] = useState(null);
  const [anchorElResources, setAnchorElResources] = useState(null);
  const {location, width} = rest;

  const servicePages = useMemo(() => {
    const pages = (cmsPages || []).filter(elem => serviceMenu.includes(elem.post_name))
    return pages.map(elem => ({...elem, url: elem.post_name}));
  }, [cmsPages]);

  // const burgerPages = useMemo(() => {
  //   const pages = (cmsPages || []).filter(elem => !serviceMenu.includes(elem.post_name))
  //   return pages
  // }, [cmsPages]);

  const resourcesMenu = useMemo(() => {
    return [
      {
        title: 'Blog',
        url: 'blog'
      },
      {
        title: 'FAQS',
        url: 'faqs'
      },
      {
        title: 'Help Center',
        url: 'https://help.calcumate.co/',
        absolute: true,
        target: '_blank'
      },
      {
        title: 'Latest features',
        url: 'latest-features'
      },
      {
        title: 'ROI Calculator',
        url: 'roi-calculator'
      },
    ];
  }, [

  ]);

  const checkScrollingState = () => {
    if (window.pageYOffset > pageYOffset) {
      setHeaderOn(true);
    } else {
      setHeaderOn(false);
    }
    manageHeaderClass(window.pageYOffset > pageYOffset);
  };

  useEffect(() => {
    if (['xs', 'sm'].includes(width)) {
      document.querySelector('#root').classList.add('tablet-mode');
    } else {
      document.querySelector('#root').classList.remove('tablet-mode');
    }
  }, [
    width
  ]);

  useEffect(() => {
    manageHeaderClass(window.pageYOffset > pageYOffset);
  }, []);

  const manageHeaderClass = (condition) => {
    if(condition) {
      document.querySelector('#root').classList.add('header-on')
    } else {
      document.querySelector('#root').classList.remove('header-on');
    }
  }

  useEffect(() => {
    if (checkHash && rest?.location?.hash) {
      const elem = document.querySelector(rest.location.hash);
      if (!elem) return
      elem.scrollIntoView({
        // optional params
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
    return () => {}
  }, []);

  useEffect(() => {
    const storagePages = localStorage.getItem('cmsPages');
    if (storagePages && storagePages !== 'undefined') {
      const cmsPages = (JSON.parse(storagePages || '') || []);
      setCmsPages(cmsPages)
    }
    cmsHelpers().then(pages => {
      if (pages.data) {
        localStorage.setItem('cmsPages', JSON.stringify(pages.data))
        setCmsPages(pages.data)
      }
    }).catch(()=>localStorage.setItem('cmsPages', JSON.stringify([])));
    if (location.pathname !== '/blog') {
      getCmsPosts().then(posts => {
        if (posts.data) {
          localStorage.setItem('cmsPosts', JSON.stringify(posts.data))
        }
      }).catch(()=>localStorage.setItem('cmsPosts', JSON.stringify([])));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollingState);
    return () => window.removeEventListener('scroll',checkScrollingState)
  });

  const handleClickService = (event) => {
    setAnchorElService(event.currentTarget);
  };

  const handleResourceMenu = (event) => {
    setAnchorElResources(event.currentTarget);
  }

  const handleCloseResourceMenu = (event) => {
    setAnchorElResources(null);
  }

  const handleCloseService = () => {
    setAnchorElService(null);
  };

  const handleSelect = (event, page) => {
    event.preventDefault();
    let path = '';
    const absolute = page.absolute;
    const target = page.target || '_self';

    if (typeof page === 'string') {
      path = page;
    } else {
      path = page.url;
    }
    if (absolute) {
      window.open(path,target).focus();
      return;
    }
    window.location.replace(`${window.location.origin}/${path}`);
  }

  return (
    <div
      className={classNames(classes.headerBackground, {
        [classes.onScrolling]: headerOn,
      })}
    >
      <Grid
        container
        className={classNames(
          classes.headerContainer, 
          'header-conteiner',
          {
            [classes.tableMode]: ['xs', 'sm'].includes(width)
          }
        )}
        spacing={0}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item className={classNames(classes.headerItemLogo, 'header-logo', {
          [classes.headerItemLogoHeadeOn]: headerOn,
  
        })}>
          <HomeHeaderLogo
            headerOn={headerOn}
          />
        </Grid>
        <Hidden mdUp>
          <Grid item style={{marginLeft: 'auto'}}>
            <Fab
              color="primary"
              //aria-label="Edit"
              size="small"
              onClick={() => setTheDrawer(!theDrawer)}
              className={{
                [classes.primaryBackground]: !!theDrawer
              }}
            >
              <MenuRounded />
            </Fab>
            <Drawer
              anchor="left"
              open={theDrawer}
              onClose={() => setTheDrawer(!theDrawer)}
            >
              <div style={{ width: 250, boxSizing: "border-box", padding: 15 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      borderBottom: "solid 1px #EFF5F7",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={elipseShineIso}
                      style={{ padding: 20 }}
                      alt="Calcumate"
                    ></img>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ borderBottom: "solid 1px #EFF5F7" }}
                  >
                    {
                      servicePages.length ? (
                        <>
                          <Button
                            size="small"
                            fullWidth
                            color="primary"
                            component={'span'}
                            onClick={handleClickService}
                          >
                            SERVICES <ExpandMoreIcon role={'menu'} />
                          </Button>
                          <Menu
                            id="service-menu"
                            anchorEl={anchorElService}
                            keepMounted
                            open={Boolean(anchorElService)}
                            onClose={handleCloseService}
                          >
                            {servicePages.map((page, key) => {
                              return (
                                <MenuItem key={key} onClick={event => handleSelect(event, page)}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ borderBottom: "solid 1px #EFF5F7" }}
                                    key={key}
                                  ><Button
                                    size="small"
                                    fullWidth
                                    color="primary"
                                    component={Link}
                                    className={classes.resetHoverLink}
                                    to={`/${page.post_name}`}
                                  >
                                    {page.post_title}
                                  </Button></Grid>
                                </MenuItem>
                              )
                            })
                            }
                          </Menu>
                        </>
                      ) : null
                    }
                    <AnchorLinkComponent
                      offset="100"
                      href="#demo-section"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        size="small"
                        fullWidth
                        color="primary"
                        onClick={() => setTheDrawer(!theDrawer)}
                      >
                        Calculator
                      </Button>
                    </AnchorLinkComponent>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ borderBottom: "solid 1px #EFF5F7" }}
                  >
                    <Button
                      size="small"
                      fullWidth
                      color="primary"
                      component={Link}
                      to={`${window.location.origin}/pricing`}
                      onClick={event => handleSelect(event, 'pricing')}
                    >
                      Pricing
                    </Button>
                  </Grid>
                  <>
                    <Button
                      size="small"
                      fullWidth
                      color="primary"
                      component={'span'}
                      onClick={handleResourceMenu}
                    >
                      RESOURCES <ExpandMoreIcon role={'menu'} />
                    </Button>
                    <Menu
                      id="resources-menu"
                      anchorEl={anchorElResources}
                      keepMounted
                      open={Boolean(anchorElResources)}
                      onClose={handleCloseResourceMenu}
                    >
                      {resourcesMenu.map((page, key) => {
                        return (
                          <MenuItem key={key} onClick={event => handleSelect(event, page)}>
                            <Grid
                              item
                              xs={12}
                              style={{ borderBottom: "solid 1px #EFF5F7" }}
                              key={key}
                            ><Button
                              size="small"
                              fullWidth
                              color="primary"
                              className={classes.resetHoverLink}
                            >
                              {page.title}
                            </Button></Grid>
                          </MenuItem>
                        )
                      })
                      }
                    </Menu>
                  </>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/login"
                      data-cy={'login-button--mobile'}
                    >
                      Login
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to="/pricing"
                      data-cy={'sign-up-button--mobile'}
                    >
                      Sign up free
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid 
            item 
            style={{marginLeft: 'auto'}}
            className={classNames(classes.headerMenuWrap, 
              {
                [classes.isHeaderOn]: headerOn
              }
              )
            } 
          >
            {
              servicePages.length ? (
                <>
                  <Typography
                    variant="subtitle2"
                    component={'span'}
                    className={classNames(
                      classes.standardLink, 
                      classes.headerMenuLink,
                      {
                        [classes.whiteLink]: !headerOn,
                      })}
                    onClick={handleClickService}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent:' center'
                    }}
                    data-cy={'services-menu__button'}
                  >
                    SERVICES <ExpandMoreIcon style={{marginLeft: 7}} role={'menu'} />
                  </Typography>
                  <Menu
                    id="service-menu-desktop"
                    anchorEl={anchorElService}
                    keepMounted
                    open={Boolean(anchorElService)}
                    onClose={handleCloseService}
                    data-cy={'services-menu'}
                  >
                    {servicePages.map((page, key) => {
                      return (
                        <MenuItem key={key} onClick={event => handleSelect(event, page)}>
                          <Grid
                            item
                            xs={12}
                            style={{ borderBottom: "solid 1px #EFF5F7" }}
                            key={key}
                            data-cy={'menu--services'}
                          >
                            <Button
                              size="small"
                              fullWidth
                              color="primary"
                              className={classes.resetHoverLink}
                            >
                              {page.post_title}
                            </Button>
                          </Grid>
                        </MenuItem>
                      )
                    })
                    }
                  </Menu>
                </>
              ) : null
            }
            <AnchorLinkComponent
              offset="100"
              href="#demo-section"
              className={classNames(classes.standardLink, {
                [classes.whiteLink]: !headerOn,
              })}
            >
              Calculator
            </AnchorLinkComponent>
            <Typography
              variant="subtitle2"
              component={Link}
              to="/pricing"
              className={classNames(classes.standardLink, {
                [classes.whiteLink]: !headerOn,
              })}
              data-cy={'menu__item-pricing-page'}
            >
              Pricing
            </Typography>
            {/*<Typography*/}
            {/*  variant="subtitle2"*/}
            {/*  component={Link}*/}
            {/*  to="/contact-us"*/}
            {/*  className={classNames(classes.standardLink, {*/}
            {/*    [classes.whiteLink]: !headerOn,*/}
            {/*  })}*/}
            {/*>*/}
            {/*  Contact*/}
            {/*</Typography>*/}
            <>
              <Typography
                variant="subtitle2"
                component={'span'}
                className={classNames(
                  classes.standardLink, 
                  classes.headerMenuLink,
                  {
                    [classes.whiteLink]: !headerOn,
                  })}
                onClick={handleResourceMenu}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:' center'
                }}
                data-cy={'services-menu__button'}
              >
                RESOURCES <ExpandMoreIcon style={{marginLeft: 7}} role={'menu'} />
              </Typography>
              <Menu
                id="resources-menu"
                anchorEl={anchorElResources}
                keepMounted
                open={Boolean(anchorElResources)}
                onClose={handleCloseResourceMenu}
              >
                {resourcesMenu.map((page, key) => {
                  return (
                    <MenuItem key={key} onClick={event => {
                      handleSelect(event, page);
                    }}>
                      <Grid
                        item
                        xs={12}
                        style={{ borderBottom: "solid 1px #EFF5F7" }}
                        key={key}
                      >
                        <Button
                          size="small"
                          fullWidth
                          color="primary"
                          className={classes.resetHoverLink}
                        >
                          {page.title}
                        </Button>
                      </Grid>
                    </MenuItem>
                  )
                })
                }
              </Menu>
            </>
            <span
              className={classNames(classes.navSeparator, {
                [classes.whiteLink]: !headerOn,
              })}
            >
              |
            </span>
            {/*<Button*/}
            {/*  size="small"*/}
            {/*  variant="outlined"*/}
            {/*  color={headerOn ? "primary" : "default"}*/}
            {/*  component={Link}*/}
            {/*  to="/login"*/}
            {/*>*/}
            {/*  <AccountCircleRoundedIcon />*/}
            {/*</Button>*/}
            <UserIcon headerOn={headerOn} />
            <Button
              size="small"
              variant="contained"
              color={headerOn ? "primary" : "default"}
              component={Link}
              to="/pricing"
              data-cy={'sign-up-button'}
            >
              Sign up free
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default withRouter(withWidth()(HomeHeader));
